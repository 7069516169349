import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAXM6gTG4nxyer-PywLzRfof6bFjXdycvw",
  authDomain: "iot.avi-portfolio.net",
  databaseURL: "https://avi-portfolio-apis-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "avi-portfolio-apis",
  storageBucket: "avi-portfolio-apis.appspot.com",
  messagingSenderId: "891226803447",
  appId: "1:891226803447:web:d0b43fc8ac7c0c920d17f2"
}

const app = initializeApp(firebaseConfig);

const database = getDatabase(app);
const auth = getAuth(app);

export {database, auth};