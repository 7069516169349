import './App.css';
import { React, useLayoutEffect} from 'react';
import { ConfigProvider, theme } from 'antd';
import { Route, Routes } from 'react-router-dom';
import SwitchUI from './SwitchUI';
import LoginPage from './LoginPage';

function App() {
  useLayoutEffect(() => {
    document.documentElement.setAttribute(
      'data-prefers-color-scheme',
      window.matchMedia('(prefers-color-scheme: dark)').matches? 
      "dark" : "light"
    )
  }, []);

  return (
    <ConfigProvider
    theme={{
      algorithm: window.matchMedia('(prefers-color-scheme: dark)').matches? theme.darkAlgorithm : theme.defaultAlgorithm,
      token: {
        fontFamily: "Segoe UI, system-ui, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif"
      }
    }} >
      <div className="App">
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/switch" element={<SwitchUI />} />
          <Route path="/" element={<LoginPage />} />
        </Routes>
      </div>
    </ConfigProvider>
  );
}

export default App;
