import './App.css';
import { Button } from 'antd';
import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleAuthProvider, indexedDBLocalPersistence, setPersistence } from 'firebase/auth';
import { signInWithRedirect } from "firebase/auth";
import { ref, get } from "firebase/database";
import { auth, database as db } from './firebase';
import { GoogleOutlined } from '@ant-design/icons';

const provider = new GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/userinfo.email');
provider.addScope('https://www.googleapis.com/auth/userinfo.profile');

function LoginPage() {
  const navigate = useNavigate();
  const [ signinRequired, setSigninRequired ] = useState(false);

  auth.beforeAuthStateChanged((user) => {
    if(user) setSigninRequired(false);
  })

  auth.onAuthStateChanged(async (user) => {
    try {
      if(user) {
        if(await userExists()) navigate("/switch");
      } else setSigninRequired(true);
    } catch (err) {
      setSigninRequired(true);
    }
  });

  const userExists = async () => {
    const userRef = ref(db, "/users");
    const snap = await get(userRef);

    return snap.exists();
  };

  const googleSignin = async () => {
    setPersistence(auth, indexedDBLocalPersistence)
    .then(() => {
      return signInWithRedirect(auth, provider);
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
  };

  return (
    <div className="login-form">
      <Button type="primary" icon={<GoogleOutlined />} disabled={!signinRequired} size={"large"} onClick={googleSignin}>
        Sign In With Google
      </Button>
    </div>
  );
}

export default LoginPage;